import React, { FC, useState } from 'react'
import { useTheme } from '@mui/material/styles'
// mui icon imports
import AccessTime from '@mui/icons-material/AccessTime'
// custom imports
import { getMoldingMachineDisplayData, logProduction, preCheckOpenLogProduction } from './MoldingApi'
import { colors } from '../../../utils/colors'
import { LibraryBooks } from '@mui/icons-material'
import { MOLDING } from '../../../utils/globalComps/ProductionLog/ProductionLogHelpers'
import { useEffectApi } from '../../../utils/globals'
import { printLabel } from '../../../utils/labelPrinting/labelPrinting'
import PageTitle from '../../../utils/globalComps/PageTitle'
import BaseContent from '../../../utils/globalComps/BaseContent'
import MachineLoadDisplay from '../../../utils/globalComps/MachineLoadDisplay/MachineLoadDisplay'
import ApiTable from '../../../utils/table/ApiTable'
import LogProduction from '../../../utils/globalComps/LogProduction/LogProduction'
import SetupsheetViewHelper from '../management/setupsheets/SetupsheetViewHelper'
import ProductionLogModal from '../../../utils/globalComps/ProductionLog/ProductionLogModal'
import ProcessingUserActions from '../../../utils/globalComps/ProcessingUserActions/ProcessingUserActions'

interface MoldingProps {
  id: number
}
/**
 * Molding component
 * @param id: machine id
 */
const Molding: FC<MoldingProps> = ({id}) => {

  const [machineDisplayLoading, setMachineDisplayLoading] = useState<boolean>(true)
  const [productivityEnabled, setProductivityEnabled] = useState<boolean>(false)
  const [canViewProductivity, setCanViewProductivity] = useState<boolean>(false)
  const [productivityRefresh, setProductivityRefresh] = useState<boolean>(false)
  const [canViewProduction, setCanViewProduction] = useState<boolean>(false)
  const [permissions, setPermissions] = useState<any>(undefined)
  const [machine, setMachine] = useState<any>({})
  const [arms, setArms] = useState<any[]>([])
  const [scrapCodeList, setScrapCodeList] = useState<any[]>([])
  const [shifts, setShifts] = useState<any[]>([])
  const [refreshMachineDisplay, setRefreshMachineDisplay] = useState<boolean>(false)
  const [tableRefresh, setTableRefresh] = useState<boolean>(false)
  const [tableRefreshSilent, setTableRefreshSilent] = useState<boolean>(false)
  const [moldedTableData, setMoldedTableData] = useState<any[]>([])
  const [logProductionItems, setLogProductionItems] = useState<any[]>([])
  const [logProductionErrors, setLogProductionErrors] = useState<any[]>([])
  const [logProductionOffMachine, setLogProductionOffMachine] = useState<boolean>(false)

  const [setupsheetItemName, setSetupsheetItemName] = useState<string>('')
  const [setupsheetMachineName, setSetupsheetMachineName] = useState<string>('')
  const [setupsheetViewOpen, setSetupsheetViewOpen] = useState<boolean>(false)

  const [productionLogsOrderId, setProductionLogsOrderId] = useState<number | undefined>(undefined)
  const [productionLogsOpen, setProductionLogsOpen] = useState<boolean>(false)

  const theme: any = useTheme()

  const refreshProductivity = () => setProductivityRefresh(!productivityRefresh)
  const refreshMachine = () => setRefreshMachineDisplay(!refreshMachineDisplay)
  const refreshTableSilent = () => {
    setTableRefreshSilent(true)
    setTableRefresh(!tableRefresh)
  }

  useEffectApi(() => {
    console.log('machine use effect :', id)
    document.title = 'Molding | RotoEdgePro'

    getMoldingMachineDisplayData(id, (data: any) => {
      if (data.machine) {
        setMachine(data.machine)
        document.title = `Molding | ${data.machine.name} | RotoEdgePro`
      }
      if (data.arms)
        setArms(data.arms)
      if (data.scrapCodes)
        setScrapCodeList(data.scrapCodes)
      if (data.shifts)
        setShifts(data.shifts)

      setCanViewProductivity(data.canViewProductivity)
      setCanViewProduction(data.canViewProduction)
      setProductivityEnabled(data.productivityEnabled)

      setMachineDisplayLoading(false)
    })

    setTableRefresh(!tableRefresh)
  }, [setMachine, setArms, id])

  // helpers
  const handlePermissions = (permissions: any[]) => setPermissions(permissions)
  const getHeaders = () => {
    let headers: string[] = ['Order', 'Arm', 'Item', 'Description', 'Ship Date', 'Quantity', 'Balance']

    for (const shift of shifts)
      headers.push(shift.min_name)

    headers.push('N/C')
    headers.push('')

    return headers
  }
  const getRows = () => {
    let rows: string[] = ['number', 'arm', 'item', 'description', 'ship_date', 'quantity', 'balance']

    for (const shift of shifts)
      rows.push(shift.name)

    rows.push('non_conforming_count')

    return rows
  }
  const getLoadedItems = (data: any[]) => {
    setMoldedTableData(data)
  }
  const getMachineNameDisplay = () => machine.name ? `/ ${machine.name}` : ''
  // log production
  const openLogProductionIndividual = (id: number) => {
    setLogProductionOffMachine(false)
    preCheckOpenLogProduction(id, false, (data: any) => {
      if (data.success)
        setLogProductionItems(moldedTableData.filter((item: any) => item.id === id))
    })
  }
  const openLogProductionArm = (armId: number) => {
		console.log('openLogProductionArm')
    // console.log('open log prod :', armId, moldedTableData)
    setLogProductionOffMachine(false)
    preCheckOpenLogProduction(armId, true, (data: any) => {
      // console.log('preCheckOpenLogProduction data :', data)
      if (data.success)
        setLogProductionItems(moldedTableData.filter((item: any) => {
          return item.arm_id === armId
        }))
    })
  }
  const doLogProduction = (data: any) => {
    logProduction(id, {...data, offMachine: logProductionOffMachine}, (data: any) => {
      // console.log('log production resp data :', data)
      if (data.status === 'success') {
        closeLogProduction()
        refreshProductivity()
        refreshMachine()
        refreshTableSilent()

        setTimeout(() => {
          for (const label of data.labelData) {
            console.log('print label :', label)
            printLabel(label.type, label.data, label.count)
          }
        }, 500)
      } else if (data.status === 'error' && data.errors) {
        setLogProductionErrors(data.errors)
      } else {
				closeLogProduction()
			}
    })
  }
  const closeLogProduction = () => {
    setLogProductionItems([])
    setLogProductionErrors([])
  }
  // setupsheet
  const viewSetupsheet = (orderItem: any, machineName: string) => {
    console.log(`viewSetupsheet(${orderItem.item}, ${machineName})`)
    setSetupsheetItemName(orderItem.item)
    setSetupsheetMachineName(machineName)
    setSetupsheetViewOpen(true)
  }
  const closeSetupsheetView = () => setSetupsheetViewOpen(false)
  // prod logs
  const openProductionLogs = (id: number) => {
    setProductionLogsOrderId(id)
    setProductionLogsOpen(true)
  }
  const closeProductionLogs = () => {
    setProductionLogsOrderId(undefined)
    setProductionLogsOpen(false)
  }
  // helpers
  const getActions = () => {
    const actions: any = []

    if (permissions?.create)
      actions.push({icon: <AccessTime />, action: openLogProductionIndividual, text: 'Log Production', noCondition: true})
    if (canViewProduction)
      actions.push({icon: <LibraryBooks />, action: openProductionLogs, text: 'Production Logs', noCondition: true})

    return actions
  }

  return (
    <>
      <PageTitle title={`Molding ${getMachineNameDisplay()}`} />
      <BaseContent sx={{ p: 0 }} loading={machineDisplayLoading}>

        <ProcessingUserActions productivityEnabled={productivityEnabled} machineId={id} canView={canViewProductivity}
                               forceRefresh={productivityRefresh} />

        {arms.length > 0 ? <MachineLoadDisplay notCollapsible machine={machine} doRefresh={refreshMachineDisplay}
                                               onItemClick={openLogProductionArm} permissions={permissions}
                                               skipArmCallback={() => refreshProductivity()} /> : <></>}
      </BaseContent>

      {machine ?
        <ApiTable
          objectName=''
          headers={getHeaders()}
          rowFields={getRows()}
          rowActions={getActions()}
          dataField='objects'
          url={`/processing/molding/${id}/`}
          dataCallback={getLoadedItems}
          refreshable
          searchable
          legend={[
            {color: theme.palette.color.orange, text: 'Almost Done'},
            {color: theme.palette.color.red, text: 'Done'},
          ]}
          rowClickActions={[
            {field: 'item', action: (orderItem: number | undefined) => viewSetupsheet(orderItem, machine.name),
              condition: 'has_setupsheet', tooltip: 'View Setupsheet'}
          ]}
          refresh={tableRefresh}
          suppressLoadDisplay={tableRefreshSilent}
          permissionsCallback={handlePermissions}
        />
      : <></>}

      <LogProduction
        data={logProductionItems}
        onClose={closeLogProduction}
        onLog={doLogProduction}
        errors={logProductionErrors}
        scrapCodeList={scrapCodeList}
        incDecLogic
        machine
        nonConforming
        hideAvailable
      />

      <SetupsheetViewHelper open={setupsheetViewOpen} doClose={closeSetupsheetView} itemName={setupsheetItemName}
                            machineName={setupsheetMachineName} newTab />
      <ProductionLogModal open={productionLogsOpen} type={MOLDING} onClose={closeProductionLogs}
                          orderId={productionLogsOrderId} />

    </>
  )
}

export default Molding
