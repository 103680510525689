import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { Schedule as ScheduleIcon, EventBusy } from '@mui/icons-material';
import { Box, TextField, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper, LinearProgress, useTheme, Tabs, Tab, IconButton, Tooltip } from '@mui/material';
import useOrderSync, { InboundSync, OutboundSync, TokenField } from './hooks/useOrderSync';

type FormValues = {
	[key: string]: string;
};

const OrderSyncDialog = () => {
	const { 
		sendMessage, schedulable,
		inbound_sync_enabled, inbound_syncs,
		outbound_sync_enabled, outbound_syncs,
		tokenForm, isScheduled, nextRun
	} = useOrderSync();

	// State for opening and closing dialogs
	const [open, setOpen] = useState(false);
	const [tokenDialogOpen, setTokenDialogOpen] = useState(false);
	const [formValues, setFormValues] = useState<FormValues>({});
	const [progressValue, setProgressValue] = useState(0); // For 'determinate' variant
	const [progressVariant, setProgressVariant] = useState<'determinate' | 'indeterminate'>('determinate');
	const [progressColor, setProgressColor] = useState<'primary' | 'inherit'>('primary'); // Change this based on sync state
	const [syncing, setSyncing] = useState(false); // To track if sync is in progress
	const [firstSyncId, setFirstSyncId] = useState(inbound_syncs?.[0]?.id || null);
	const [activeTab, setActiveTab] = useState<'inbound' | 'outbound'>(
		inbound_sync_enabled ? 'inbound' : 'outbound'
	);

	const theme = useTheme();

	// Clears form values when the token dialog opens
	const handleTokenOpen = () => {
		const initialFormValues = (tokenForm || []).reduce((acc, field: TokenField) => {
			acc[field.field] = '';
			return acc;
		}, {} as FormValues);
		setFormValues(initialFormValues || {});
		setTokenDialogOpen(true);
	};

	const handleTokenClose = () => {
		setTokenDialogOpen(false);
	};

	const handleFormChange = (field: string, value: string) => {
		setFormValues((prevValues) => ({
			...prevValues,
			[field]: value,
		}));
	};

	const handleSaveTokens = () => {
		sendMessage('submit_tokens', { tokens: { ...formValues } });
		setTokenDialogOpen(false);
		setFormValues({});
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	// Sync completion logic - switch to 'green' and reset after 3 seconds
	useEffect(() => {
		const currentSyncs = activeTab === 'inbound' ? inbound_syncs : outbound_syncs;
		if (currentSyncs?.[0]?.id !== firstSyncId && firstSyncId !== null) {
			setSyncing(false);
			setProgressColor('inherit');
			setProgressVariant('determinate');
			setProgressValue(100);

			setTimeout(() => {
				setProgressValue(0);
				setProgressColor('primary');
			}, 3000);
		}
	}, [inbound_syncs, outbound_syncs, activeTab]);

	const handleSyncIn = () => {
		// Start syncing, set to indeterminate
		setSyncing(true);
		setFirstSyncId(inbound_syncs.length > 0 ? inbound_syncs[0].id : 0);
		setProgressVariant('indeterminate'); // Switch to indeterminate for sync animation
		setProgressColor('primary'); // Default blue color
		sendMessage('sync_in');
	};

	const handleSyncOut = () => {
		setSyncing(true);
		setFirstSyncId(outbound_syncs.length > 0 ? outbound_syncs[0].id : 0);
		setProgressVariant('indeterminate');
		setProgressColor('primary');
		sendMessage('sync_out');
	};

	// Track the last known schedule state to detect changes
	const [lastScheduleState, setLastScheduleState] = useState(isScheduled);
	const [isTogglingSchedule, setIsTogglingSchedule] = useState(false);

	// Update loading state based on websocket response
	useEffect(() => {
		if (isTogglingSchedule && isScheduled !== lastScheduleState) {
			setIsTogglingSchedule(false);
			setLastScheduleState(isScheduled);
		}
	}, [isScheduled]);

	const handleToggleSchedule = () => {
		setIsTogglingSchedule(true);
		setLastScheduleState(isScheduled);
		sendMessage('toggle_schedule', { enabled: !isScheduled });
	};

	const renderSyncTable = () => {
		const syncs = activeTab === 'inbound' ? inbound_syncs : outbound_syncs;

		const handleCopyError = (errorMessage: string) => {
			navigator.clipboard.writeText(errorMessage);
		};

		return (
			<TableContainer component={Paper} sx={{ marginTop: 2, borderRadius: 5 }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell sx={{ textAlign: 'center' }}>
								{activeTab === 'inbound' ? 'Orders Created' : 'Units Synced'}
							</TableCell>
							<TableCell sx={{ textAlign: 'center' }}>Synced At</TableCell>
							{activeTab === 'outbound' && (
								<TableCell sx={{ textAlign: 'center' }}>Error</TableCell>
							)}
						</TableRow>
					</TableHead>
					<TableBody>
						{syncs && syncs.map((sync, index) => (
							<TableRow key={index}>
								<TableCell sx={{ textAlign: 'center' }}>
									{activeTab === 'inbound'
										? (sync as InboundSync).orders_created
										: (sync as OutboundSync).total_units_synced
									}
								</TableCell>
								<TableCell sx={{ textAlign: 'center' }}>
									{new Date(sync.created).toLocaleString()}
								</TableCell>
								{activeTab === 'outbound' && (
									<TableCell sx={{ textAlign: 'center' }}>
										{(sync as OutboundSync).error_message && (
											<Button
												onClick={() => handleCopyError((sync as OutboundSync).error_message)}
												variant="text"
												size="small"
											>
												Copy Error
											</Button>
										)}
									</TableCell>
								)}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		);
	};


	return (
		<div>
			<Button
				sx={{ borderRadius: 3 }}
				variant="outlined"
				color="primary"
				onClick={handleClickOpen}
			>
				Order Sync
			</Button>
			<Dialog open={open} onClose={handleClose} PaperProps={{ sx: { borderRadius: 5, textWrap: 'nowrap' } }} >
				<DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					Order Sync
				</DialogTitle>
				<DialogContent 
					dividers
					sx={{
						p: 2,
						minWidth: 400,  // Set a minimum width
						'& .MuiDialogContent-root': {
							padding: 0
						}
					}}
				>
					
					{/* Tabs - Only show if both syncs are enabled */}
					{inbound_sync_enabled && outbound_sync_enabled && (
						<Tabs
							value={activeTab}
							onChange={(_, newValue) => setActiveTab(newValue)}
							sx={{
								mb: 2,
								'& .MuiTabs-flexContainer': {
									width: '100%'
								},
								'& .MuiTab-root': {
									width: '50%'  // Each tab takes up half the space
								}
							}}
						>
							<Tab label="Inbound Sync" value="inbound" />
							<Tab label="Outbound Sync" value="outbound" />
						</Tabs>
					)}

					{/* Progress bar */}
					<Box sx={{ width: '100%', marginY: 2 }}>
						<LinearProgress
							variant={progressVariant}
							value={progressValue}
							color={progressColor}
							sx={{
								borderRadius: 5,
								transition: 'all 1s ease',
								...(progressColor === 'inherit' && {
									'& .MuiLinearProgress-bar': {
										backgroundColor: theme.palette.success.light,
									},
								}),
							}}
						/>
					</Box>

					{/* Schedule Info Bar */}
					{schedulable && (
						<Box
							sx={{
								mb: 2,
								p: 2,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								bgcolor: 'background.default',
								border: 1,
								borderColor: 'divider',
								borderRadius: 2,
							}}
						>
							<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
								<Typography variant="body2" fontWeight="medium">
									Scheduled Sync
								</Typography>
								{isScheduled && nextRun && (
									<Typography variant="caption" color="text.secondary">
										Next run: {nextRun}
									</Typography>
								)}
							</Box>
							<Tooltip title={isScheduled ? 'Disable scheduled sync' : 'Enable scheduled sync'}>
								<IconButton
									size="small"
									onClick={handleToggleSchedule}
									color={isScheduled ? 'primary' : 'default'}
								>
									{isScheduled ? <ScheduleIcon /> : <EventBusy />}
								</IconButton>
							</Tooltip>
						</Box>
					)}

					{/* Sync Table */}
					{renderSyncTable()}
				</DialogContent>
				<DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Box>
						<Button sx={{ borderRadius: 3 }} onClick={handleClose} color="error">
							Close
						</Button>
					</Box>
					<Box>
						{tokenForm.length > 0 && (
							<Button sx={{ borderRadius: 3 }} onClick={handleTokenOpen} color="secondary">
								Tokens
							</Button>
						)}

						{inbound_sync_enabled && (
							<Button
								onClick={handleSyncIn}
								color="primary"
								disabled={syncing}
								sx={{ borderRadius: 3 }}
							>
								Sync In
							</Button>
						)}

						{outbound_sync_enabled && (
							<Button
								sx={{ borderRadius: 3 }}
								onClick={handleSyncOut}
								disabled={syncing}
								color="primary"
							>
								Sync Out
							</Button>
						)}

						
					</Box>
				</DialogActions>
			</Dialog>


			{/* Token Dialog */}
			<Dialog open={tokenDialogOpen} onClose={handleTokenClose} PaperProps={{ sx: { borderRadius: 5 } }}>
				<DialogTitle>Token Form</DialogTitle>
				<DialogContent dividers>
					<DialogContentText>
						<Typography>
							Tokens will be securely stored on AWS Secret Manager
						</Typography>
					</DialogContentText>
					<Box component="form">
						{tokenForm && tokenForm.map((field: TokenField, index: number) => (
							<TextField
								key={index}
								label={field.label}
								name={field.field}
								fullWidth
								margin="normal"
								value={formValues[field.field] || ''}
								onChange={(e) => handleFormChange(field.field, e.target.value)}
							/>
						))}
					</Box>
				</DialogContent>
				<DialogActions>
					<Button sx={{ borderRadius: 3 }} onClick={handleTokenClose} color="error">
						Close
					</Button>
					<Button sx={{ borderRadius: 3 }} onClick={handleSaveTokens} color="primary">
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default OrderSyncDialog;